import { combineReducers, createSlice } from "@reduxjs/toolkit";

const categories = [];

export const categorySlice = createSlice({
  name: "Categories",
  initialState: { value: categories },
  reducers: {
    addCategories: (state, action) => {
      state.value = [...state.value, action.payload];
    },
  },
});

export const { addCategories } = categorySlice.actions;

const category = [];

export const categoryQuerySlice = createSlice({
  name: "Category",
  initialState: { value: category },
  reducers: {
    addCategory: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addCategory } = categoryQuerySlice.actions;

const routes = [];

export const routeSlice = createSlice({
  name: "productRoutes",
  initialState: { value: routes },
  reducers: {
    addRoutes: (state, action) => {
      state.value = [...state.value, action.payload];
    },
  },
});

export const { addRoutes } = routeSlice.actions;

const routeReducer = combineReducers({
  allRoutes: routeSlice.reducer,
  allCategories: categorySlice.reducer,
  categoryId: categoryQuerySlice.reducer,
});

export default routeReducer;
