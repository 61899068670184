import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = "";

export const storageSlice = createSlice({
  name: "storage",
  initialState: { value: initialStateValue },
  reducers: {
    setStorage: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setStorage } = storageSlice.actions;

export default storageSlice.reducer;
