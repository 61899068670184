import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setReviewSubHeader } from "../Redux/reviewRedux";
import "./sl.css";

const StoreLocation = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setReviewSubHeader("Office location"));
  }, []);

  return (
    <div className="top-div-sl">
      <div className="sl">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>City</th>
              <th>Locality</th>
              <th>Address</th>
              <th>Office hrs.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Chennai</td>
              <td>Mugalivakkam</td>
              <td style={{ width: "400px" }}>
                77, 3rd Main Rd, Subasree Nagar,
                Mugalivakkam, Chennai, Tamil Nadu 600116. Landmark: Near Kedar
                Hospital
              </td>
              <td>10am to 7pm (Mon to Sat)</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StoreLocation;
