import axios from "axios";

export const trackPages = async (data) => {
  try {
    await axios.post(`${process.env.REACT_APP_API}/secure-page`, data);
  } catch (error) {
    console.log(error);
  }
};

export const trackLanding = async (data) => {
  try {
    await axios.post(`${process.env.REACT_APP_API}/secure-landing`, data);
  } catch (error) {
    console.log(error);
  }
};

export const trackDuration = async (data) => {
  try {
  
    await axios.post(`${process.env.REACT_APP_API}/secure-close`, data);
  } catch (error) {
    console.log(error);
  }
};
