import { combineReducers, createSlice } from "@reduxjs/toolkit";

const messageState = "";

export const messageSlice = createSlice({
  name: "message",
  initialState: { value: messageState },
  reducers: {
    addMessage: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addMessage } = messageSlice.actions;

const initialStateValue = 0;

export const notifyCountSlice = createSlice({
  name: "notificationCount",
  initialState: { value: initialStateValue },
  reducers: {
    addNotificationCount: (state) => {
      state.value += 1;
    },
    decrementNotificationCount: (state) => {
      state.value = 0;
    },
  },
});

export const { addNotificationCount, decrementNotificationCount } =
  notifyCountSlice.actions;

const tabState = true;

export const tabStateSlice = createSlice({
  name: "tabState",
  initialState: { value: tabState },
  reducers: {
    changeTabState: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeTabState } = tabStateSlice.actions;

const rootReducer3 = combineReducers({
  message: messageSlice.reducer,
  notificationCount: notifyCountSlice.reducer,
  tabState: tabStateSlice.reducer,
});

export default rootReducer3;
