import { createSlice } from "@reduxjs/toolkit";

const headerValue = "";

export const headerSlice = createSlice({
  name: "headerSelect",
  initialState: { value: headerValue },
  reducers: {
    addHeaders: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addHeaders } = headerSlice.actions;

export default headerSlice.reducer;
