import { combineReducers, createSlice } from "@reduxjs/toolkit";

const lists = "";

export const filteredListSlice = createSlice({
  name: "filteredLists",
  initialState: { value: lists },
  reducers: {
    addFilterlists: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { addFilterlists } = filteredListSlice.actions;

const onlyProducts = "";

export const onlyProductSlice = createSlice({
  name: "onlyProduct",
  initialState: { value: onlyProducts },
  reducers: {
    addProductNames: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addProductNames } = onlyProductSlice.actions;

const selectedProduct = {};

export const selectedProductSlice = createSlice({
  name: "selectedProduct",
  initialState: { value: selectedProduct },
  reducers: {
    addSelectedProduct: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addSelectedProduct } = selectedProductSlice.actions;

const filters = combineReducers({
  searchAll: filteredListSlice.reducer,
  searchonlyProducts: onlyProductSlice.reducer,
  selectedProduct: selectedProductSlice.reducer,
});
export default filters;
