import axios from "axios";

export const verifyUser = async () => {
  const token = localStorage.getItem("token");
  const headers = {
    authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/verify-user`,
      {
        headers,
      }
    );
    return response.data.success;
  } catch (error) {
    console.log(error);
  }
};
