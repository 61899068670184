import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import themeReducer from "../src/Components/Redux/theme";
import App from "./App";
import loaderRedux from "./Components/Redux/LoaderRedux";
import changeStatusRedux from "./Components/Redux/VerifyUserStatus";
import businessReducer from "./Components/Redux/business";
import contentReducer from "./Components/Redux/contentRedux";
import filters from "./Components/Redux/filterList";
import headerSelect from "./Components/Redux/headerSelect";
import routesRedux from "./Components/Redux/productRoutes";
import reviewSubHeaderRedux from "./Components/Redux/reviewRedux";
import storageReducer from "./Components/Redux/storage";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
const store = configureStore({
  reducer: {
    theme: themeReducer,
    business: businessReducer,
    storage: storageReducer,
    statusCheck: changeStatusRedux,
    routes: routesRedux,
    header: headerSelect,
    loader: loaderRedux,
    searchFilter: filters,
    content: contentReducer,
    reviewSubHeader: reviewSubHeaderRedux,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();

reportWebVitals();
