import { createSlice } from "@reduxjs/toolkit";

const value = "";

export const reviewSubHeaderSlice = createSlice({
  name: "reviewSubHeader",
  initialState: { value: value },
  reducers: {
    setReviewSubHeader: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setReviewSubHeader } = reviewSubHeaderSlice.actions

export default reviewSubHeaderSlice.reducer;