import { combineReducers, createSlice } from "@reduxjs/toolkit";

const pageName = "";

export const pageNameSlice = createSlice({
  name: "pageName",
  initialState: { value: pageName },
  reducers: {
    changePageName: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changePageName } = pageNameSlice.actions;

const initialStateValue = false;

export const contentSlice = createSlice({
  name: "content",
  initialState: { value: initialStateValue },
  reducers: {
    submitContent: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { submitContent } = contentSlice.actions;

const initialDescValue = "";

export const descriptionSlice = createSlice({
  name: "content",
  initialState: { value: initialDescValue },
  reducers: {
    submitDescription: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { submitDescription } = descriptionSlice.actions;

const contentReducer = combineReducers({
  submitValue: contentSlice.reducer,
  pageName: pageNameSlice.reducer,
  prodDesc: descriptionSlice.reducer,
});

export default contentReducer;
