import { createSlice } from "@reduxjs/toolkit";

const status = false;

export const VerifyStatusSlice = createSlice({
  name: "verifyStatus",
  initialState: { value: status },
  reducers: {
    changeStatus: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeStatus } = VerifyStatusSlice.actions;

export default VerifyStatusSlice.reducer;
